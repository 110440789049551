<template>
  <div>
    <PageHeader text="Создать новое заболевание (состояние)" />
    <NosologiaForm />
  </div>
</template>

<script>
import NosologiaForm from '@/components/forms/NosologiaForm.vue'
import PageHeader from '@/components/common/PageHeader.vue'

export default {
  components: {
    PageHeader,
    NosologiaForm,
  },
}
</script>
